import axios from 'axios';
import store from '../store/index'

export default function setup() {

    axios.interceptors.request.use(  function (config) {

        const token = (localStorage.getItem('access_token')) ? localStorage.getItem('access_token') : null;
        // console.log("StoreGettingToken")
        // console.log(token)
        //console.log(token)
        if (!token) {
            // console.log("No Token")
              return config;
        } else {
            // console.log("Has Token")
            config.headers.Authorization = `Bearer ${token}`;
            return config;
        }
        
    }, function (err) {
        return Promise.reject(err);
    });
}