<template>
  <div class="item my-5">
    <b-row class="mb-4">
      <b-col cols="12" md="6">
        <h2>Account's Total: {{ formatcurency(AccountTotal) }}</h2>
      </b-col>

      <b-col cols="12" md="6" class="d-flex">
        <b-form-input
          class="p-4"
          v-model="filter"
          placeholder="Search..."
        ></b-form-input>
        <b-input-group-append>
          <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
          <b-button v-if="PlaidAccounts" @click="PlaidSync()" class="ml-4">
            <b-icon-arrow-repeat />
          </b-button>
          <b-button class="ml-4" v-b-modal.NewEnvelope>+</b-button>
        </b-input-group-append>
      </b-col>
    </b-row>
    <b-table
      striped
      hover
      outlined
      bordered
      responsive
      show-empty
      :busy="AccountTableisBusy"
      :items="Accounts"
      :fields="Accountsfields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :filter="filter"
    >
    <template #empty="scope">
        <h4>{{ "No Accounts where loaded..." }}</h4>
      </template>

      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Data is loading...</strong>
        </div>
      </template>
      <template #emptyfiltered="scope">
        <h4>{{ "No Accounts fit your search, please try a different ones." }}</h4>
      </template>
      <template v-slot:cell(NameEdit)="data">
        <div v-if="data.item.edit == true">
          <b-form-input v-model="data.item.name" />
        </div>
        <div v-else>
          <a :href="`/Accounts/${data.item.id}`">{{ data.item.name }}</a>
        </div>
      </template>
      <template v-slot:cell(balance2)="data">
        <div v-bind:class="data.item.balance < 0 ? 'error' : 'success'">
          {{ formatcurency(data.item.balance) }}
        </div>
      </template>
      <template v-slot:cell(DescriptionEdit)="data">
        <div v-if="data.item.edit == true">
          <b-form-input v-model="data.item.description" />
        </div>
        <div v-else>
          {{ data.item.description }}
        </div>
      </template>
      <template v-slot:cell(StartingBalanceEdit)="data">
        <div v-if="data.item.edit == true">
          <!-- <b-form-input v-model="data.item.startingbalance" /> -->
          <money
            v-model="data.item.startingbalance"
            class="form-control"
          ></money>
        </div>
        <div v-else>
          {{ data.item.startingbalance }}
        </div>
      </template>
      <template v-slot:cell(dateformated)="data">
        {{ new Date(data.item.date).format("YYYY-MM-DD hh:mm A") }}
      </template>

      <template v-slot:cell(Actions)="data">
        <b-button-group>
          <b-button
            size="sm"
            @click="
              saveAccount(data.item);
              data.item.edit = !data.item.edit;
            "
            class="mr-1"
          >
            <b-icon-pencil v-if="!data.item.edit" />
            <b-icon-save v-if="data.item.edit" />
          </b-button>
          <b-button
            v-if="data.item.edit"
            size="sm"
            @click="DeleteAccount(data.item)"
            class="mr-1"
            variant="danger"
          >
            <b-icon-trash />
          </b-button>
        </b-button-group>
      </template>
    </b-table>
    <NewItem newtype="Account" />
  </div>
</template>
<script>
import axios from "axios";
import NewItem from "./NewItem.vue";

export default {
  title: "Accounts",
  name: "Accounts",
  components: { NewItem },
  methods: {
    async saveAccount(i) {
      if (i.edit) {
        //console.log(i);

        axios.post("/api/accounts", i).then((e) => {
          setTimeout(
            function () {
              console.log("test");
              this.refresh();
            }.bind(this),
            800
          );
        });
      }
    },
    PlaidSync() {
      axios.post("/api/plaid/transactions/getall").then((res) => {
        //console.log(res.data);
      });
    },
    refresh() {
      this.AccountTableisBusy = true;
      axios.get("/api/accounts/balances").then((res) => {
        //console.log(res.data);
        if (res.data.length > 0) {
          this.Accounts = res.data.map((a) => {
            a.edit = false;
            return a;
          })
        } 
        this.AccountTableisBusy = false;
      });
    },
    DeleteAccount(data) {
      if (confirm(`Delete Account ${data.name} and all it's transactions?`)) {
        console.log(data);
        var index = this.Accounts.findIndex((t) => {
          return t.id == data.id;
        });
        console.log(index);
        axios.delete("/api/accounts", { data }).then((e) => {
          this.Accounts.splice(index, 1);
        });
      }
    },
  },
  data() {
    return {
      filter: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      sortBy: "date",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      AccountTableisBusy: null,
      Accounts: [],
      Accountsfields: [
        {
          key: "NameEdit",
          label: "Name",
          class: "col-4",
          variant: "none",
          sortDirection: "desc",
        },

        {
          key: "DescriptionEdit",
          label: "Description",
          variant: "none",
          class: "col-3",
        },
        {
          key: "balance2",
          label: "Balance",
          variant: "none",
          class: "col-2",
        },
        {
          key: "Actions",
          label: "",
          variant: "none",
          class: "col-3",
        },
      ],
    };
  },
  created() {
    this.refresh();
  },
  computed: {
    AccountTotal() {
      let sum = 0;
      this.Accounts.forEach((element) => {
        sum += Number(element.balance);
      });
      return Number(sum.toFixed(2));
    },
    PlaidAccounts() {
      return this.Accounts.some((a) => a.plaidsync);
    },
  },
};
</script>
<style scoped></style>
