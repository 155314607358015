<template>
  <div>
    <b-modal title="Welcome!" id="NewUser" hide-footer no-close-on-esc no-close-on-backdrop hide-header-close>
      <h1>Welcome to EasyEnvelopes!</h1>
      <p>We're glad you're here. To get started, why not take our 60-day free trial for a spin?</p>
      <b-button @click="startTrial()">Start Trial</b-button>
      <b-button @click="Logout()">No, Thank You</b-button>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios';
import { mapMutations } from 'vuex';

export default {
  name: "NewUser",
  computed: {

  },
  methods: {
    ...mapMutations([
      'SET_USER_STATUS' // Add the mutation name here
    ]),
    Logout() {
      this.$store.dispatch('logout')
    },
    async startTrial() {
      await axios.post('/api/user/starttrial')
      .then((r) => {
        this.SET_USER_STATUS(r.data); // Use the mutation here
        this.$bvModal.hide('NewUser')
      })
      .catch((error) => {
        console.log(error);
      });
    },
    // ...
  },
  // ...
};
</script>