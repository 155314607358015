<template>
  <div>
    <b-row>
      <b-col cols="4" md="4">
        <span>
          <h2 style="display: inline">Transactions</h2>
          <h5 style="display: inline">
            {{ " (" + transactions.length + ")" }}
          </h5>
        </span>
      </b-col>
      <b-col cols="8" md="8" class="d-flex">
        <b-form-input v-model="filter" type="search" placeholder="Search..."></b-form-input>
        <b-input-group-append>
          <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
        </b-input-group-append>

        <b-dropdown :disabled="SelectedTableRows.length < 2" class="ml-2" text="Bulk">
          <b-dropdown-item v-b-modal.BulkAssignEnvelope>Assign Envelope</b-dropdown-item>
          <b-dropdown-item @click="BulkDelete(SelectedTableRows)">Delete</b-dropdown-item>
        </b-dropdown>
        <b-modal title="Bulk Assign Envelope" id="BulkAssignEnvelope" @ok="BulkAssignEnvelope">
          <b-form-group label-cols="3" content-cols="9" label="Target Envelope" label-for="target-envelope">
            <model-select id="target-envelope" :options="EnvelopeOptions" v-model="BulkAssignEnvelopeId"></model-select>
          </b-form-group>
        </b-modal>
        <b-input-group-append>
          <b-button v-b-modal.NewEnvelope>+</b-button>
            <b-button @click="redirectToReceiptUpload">
              <b-icon-receipt />
            </b-button>
          <b-button v-b-modal.ImportTransactions>
            <b-icon-cloud-upload />
          </b-button>
          <b-button v-b-modal.ExportTransactions>
            <b-icon-cloud-download />
          </b-button>
          <b-button v-b-toggle.sidebar-options>Options</b-button>
        </b-input-group-append>
      </b-col>

      <b-sidebar id="sidebar-options" title="Options" right shadow>
        <b-container>
          <b-checkbox @change="Filtertransactions" v-model="ShowOnlyUnallocated">Unassigned</b-checkbox>
          <b-checkbox @change="Filtertransactions" v-model="ShowNoAccount">No Account</b-checkbox>
          <b-form-group label-cols-sm="4" label-cols-lg="3" content-cols-sm content-cols-lg="7" label="Start Date:"
            label-for="start-date">
            <b-form-datepicker @input="GetTransactions()" :value-as-date="true" :date-format-options="{
              year: 'numeric',
              month: 'short',
              day: '2-digit',
              weekday: 'short',
            }" type="date" v-model="SearchDateStart" />
          </b-form-group>
          <b-form-group label-cols-sm="4" label-cols-lg="3" content-cols-sm content-cols-lg="7" label="End Date:"
            label-for="start-date">
            <b-form-datepicker @input="GetTransactions()" :value-as-date="true" :date-format-options="{
              year: 'numeric',
              month: 'short',
              day: '2-digit',
              weekday: 'short',
            }" type="date" v-model="SearchDateEnd" />
          </b-form-group>
          <b-form-group label-cols="7" content-cols="5" label="Table Select Mode:" label-for="table-select-mode">
            <b-form-checkbox v-model="TableSelectModeChecked" name="table-select-mode" switch />
          </b-form-group>

          <div v-for="filter in CustomFilters" style="margin: 8px 0" :key="filter.id">
            <b>{{ ProperCase(filter.field) }}</b>
            <b-button v-b-toggle="'collapse-' + filter.field" variant="primary">Toggle Collapse</b-button>
            <b-collapse :id="'collapse-' + filter.field" class="mt-2">
              <div v-for="value in filter.values" v-bind:key="value">
                <b-card>
                  <b-checkbox v-bind:id="value" v-bind:name="value" v-bind:value="value" v-on:change="
                    onCheck($event.target.checked, filter.field, value)
                    ">
                    <span v-if="value == null">{{ "Not Set" }}</span>
                    <span v-if="filter?.field == 'envelope'" v-bind:for="value">{{ value?.name || "" }}</span>
                    <span v-else-if="filter.field !== 'Tech'" v-bind:for="value">{{ value }}</span>
                  </b-checkbox>
                </b-card>
              </div>
            </b-collapse>
          </div>
        </b-container>
      </b-sidebar>
    </b-row>
    <b-table selectable :busy="TransactionTableisBusy" :select-mode="TableSelectMode" id="my-table" small striped hover
      outlined bordered responsive show-empty :items="transactionsFiltered" :fields="transactionsfields"
      :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter" :current-page="currentPage" :per-page="perPage"
      @filtered="onFiltered" @row-selected="onRowSelected">
      <template #empty="scope">
        <h4>{{ "No Transactions where loaded..." }}</h4>
      </template>
      <template #emptyfiltered="scope">
        <h4>{{ "No Transactions fit your search, please try a different ones." }}</h4>
      </template>
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Data is loading...</strong>
        </div>
      </template>
      <template #cell(selected)="{ rowSelected }">
        <template v-if="rowSelected">
          <span aria-hidden="true">&check;</span>
          <span class="sr-only">Selected</span>
        </template>
        <template v-else>
          <span aria-hidden="true">&nbsp;</span>
          <span class="sr-only">Not selected</span>
        </template>
      </template>
      <template v-slot:cell(AccountEdit)="data">
        <div v-if="data.item.edit == true">
          <model-select id="input-envelope" :options="AccountOptions" v-model="data.item.accountId"></model-select>
        </div>
        <div v-else>
          {{ data.item.account?.name || "" }}
        </div>
      </template>
      <template v-slot:cell(EnvelopeEdit)="data">
        <div v-if="data.item.edit == true">
          <b-input-group>
            <b-checkbox v-model="data.item.split" name="split">
              Split?
            </b-checkbox>

            <a class="s" v-if="data.item.split" @click="
              TransactionSplitEdit = data.item;
            $bvModal.show('SplitTransaction');
            ">Edit Split</a>
            <model-select v-if="!data.item.split" id="input-envelope" :options="EnvelopeOptions"
              v-model="data.item.envelopeId"></model-select>
          </b-input-group>
        </div>
        <div v-else>
          <div v-if="data.item.split">--Split--</div>
          <!-- <div v-else-if="data.item.envelopeId === null">{{ "" }}</div> -->
          <div v-else-if="isMobile">{{ data.item.envelope?.name }}</div>
          <div v-else-if="data.item.envelope?.category?.name !== undefined && data.item.envelope?.name !== undefined">
            {{ data.item.envelope?.category?.name + ' | ' + data.item.envelope?.name }}
          </div>
          <div v-else>{{ "" }}</div>
        </div>
      </template>
      <template v-slot:cell(PayeeEdit)="data">
        <div v-if="data.item.edit == true &&
          data.item.type != 'Transfer' &&
          data.item.type != 'startingbalance'
          ">
          <b-form-input v-model="data.item.payee" />
        </div>
        <div v-else>
          {{ data.item.payee }}
        </div>
      </template>
      <template v-slot:cell(AmountEdit)="data">
        <div v-if="!data.item.edit" v-bind:class="data.item.amount < 0 ? 'error' : 'success'">
          {{ formatcurency(data.item.amount) }}
        </div>
        <div></div>
        <money v-bind:class="data.item.amount < 0 ? 'error' : 'success'" v-if="data.item.edit" class="form-control"
          v-model="data.item.amount"></money>
      </template>

      <template v-slot:cell(CheckNumberEdit)="data">
        <div v-if="data.item.edit == true">
          <b-form-input v-model="data.item.checknumber" />
        </div>
        <div v-else>
          {{ data.item.checknumber }}
        </div>
      </template>
      <template v-slot:cell(DateEdit)="data">
        <div v-if="data.item.edit == true">
          <b-form-datepicker :date-format-options="{
            year: 'numeric',
            month: 'short',
            day: '2-digit',
            weekday: 'short',
          }" :value-as-date="true" type="date" v-model="data.item.date" />
          <!-- <b-form-input type="date" v-model="data.item.date" /> -->
        </div>
        <div v-else>
          {{ new Date(data.item.date).format("MM-DD-YYYY") }}
        </div>
      </template>
      <template v-slot:cell(Actions)="data">
        <b-button-group>
          <b-button v-if="!isMobile" size="sm" @click="saveTransaction(data.item)" class="mr-1">
            <!-- <b-icon-pencil v-if="!data.item.edit"  /> -->
            <b-icon-pencil v-if="!data.item.edit" />
            <b-icon-save v-if="data.item.edit" />
          </b-button>
          <b-button v-if="isMobile" size="sm" @click="saveTransaction(data.item)"
            v-b-modal="'EditTransaction-' + data.item.id" class="mr-1">
            <!-- <b-icon-pencil v-if="!data.item.edit"  /> -->
            <b-icon-pencil v-if="!data.item.edit" />
            <b-icon-save v-if="data.item.edit" />
          </b-button>
          <b-button v-if="data.item.edit" size="sm" @click="Delete(data.item)" class="mr-1" variant="danger">
            <b-icon-trash />
          </b-button>
          <b-modal v-if="data.item.edit" title="Edit Transaction" :id="'EditTransaction-' + data.item.id" @show="if (data.item.checknumber) {
            CheckQ = true;
          }
            " @hidden="data.item.edit = false" @ok="saveTransaction(data.item)">
            <b-form-group label-cols="3" content-cols="9" label="Type" label-for="input-type">
              <b-form-select disabled v-model="Newtype" :options="NewSelect" id="input-type">
              </b-form-select>
            </b-form-group>
            <b-form-group v-if="Newtype == 'Envelope'" label-cols="3" content-cols="9" label="Category"
              label-for="input-categoryId">
              <model-select id="input-categoryId" :options="CategoryOptions" v-model="data.item.categoryId">
              </model-select>
            </b-form-group>
            <b-form-group v-if="Newtype == 'Transaction'" label-cols="3" content-cols="9" label="Date"
              label-for="input-date">
              <b-form-datepicker :value-as-date="true" :date-format-options="{
                  year: 'numeric',
                  month: 'short',
                  day: '2-digit',
                  weekday: 'short',
                }" type="date" v-model="data.item.date" id="input-date" />
            </b-form-group>

            <b-form-group v-if="Newtype == 'Transaction'" label-cols="3" content-cols="9" label="Check?"
              label-for="input-envelope">
              <b-input-group>
                <b-form-checkbox v-model="CheckQ" @change="                                                  if (!CheckQ) {
                    data.item.checknumber = null;
                  }
                    ">{{ CheckQ ? "Yes" : "No" }}</b-form-checkbox>
              </b-input-group>
            </b-form-group>
            <b-form-group v-if="Newtype == 'Transaction' && CheckQ" label-cols="3" content-cols="9" label="Check #"
              label-for="input-checknumber">
              <b-input type="number" v-model="data.item.checknumber" id="input-checknumber"></b-input>
            </b-form-group>

            <b-form-group v-if="Newtype == 'Transaction'" label-cols="3" content-cols="9" label="Account"
              label-for="input-Account">
              <model-select id="input-Account" :options="AccountOptions" v-model="data.item.accountId">
              </model-select>
            </b-form-group>
            <b-form-group v-if="Newtype == 'Transaction'" label-cols="3" content-cols="9" label="Payee"
              label-for="input-payee">
              <b-input v-model="data.item.payee" id="input-payee"></b-input>
            </b-form-group>
            <b-form-group v-if="Newtype != 'Transaction' && Newtype != 'Transfer'" label-cols="3" content-cols="9"
              label="Name" label-for="input-name">
              <b-input v-model="data.item.name" id="input-name"></b-input>
            </b-form-group>
            <b-form-group v-if="Newtype != 'Transfer'" label-cols="3" content-cols="9" label="Description"
              label-for="input-description">
              <b-input v-model="data.item.description" id="input-description"></b-input>
            </b-form-group>
            <b-form-group v-if="Newtype == 'Account'" label-cols="3" content-cols="9" label="Starting Balance"
              label-for="input-startingbalance">
              <b-input type="number" v-model="data.item.startingbalance" id="input-startingbalance"></b-input>
            </b-form-group>
            <b-form-group v-if="Newtype == 'Transaction' || Newtype == 'Transfer'" label-cols="3" content-cols="9"
              label="Amount" label-for="input-Amount">
              <!-- <b-input type="number" v-model="data.item.amount" id="input-Amount"></b-input> -->
              <money v-bind:class="data.item.amount < 0 ? 'error' : 'success'" v-model="data.item.amount"
                class="form-control"></money>
            </b-form-group>
            <b-form-group v-if="Newtype == 'Transfer'" label-cols="3" content-cols="9" label="Source Envelope"
              label-for="input-Sourceenvelope">
              <model-select v-if="!data.item.split" id="input-envelope" :options="EnvelopeOptions"
                v-model="data.item.envelopeId"></model-select>
            </b-form-group>
            <b-form-group v-if="Newtype == 'Transfer'" label-cols="3" content-cols="9" label="Source Balance"
              label-for="input-SourceBalance">
              <div v-bind:class="SourceBalance < 0 ? 'error' : 'success'">
                {{ formatcurency(SourceBalance) }}
              </div>
            </b-form-group>
            <b-form-group label="Envelope" label-for="input-envelope">
              <b-checkbox v-model="data.item.split" name="split">
                Split?
              </b-checkbox>

              <a class="s" v-if="data.item.split" @click="
                    TransactionSplitEdit = data.item;
                  $bvModal.show('SplitTransaction');
                  ">Edit Split</a>
              <model-select v-if="!data.item.split" id="input-envelope" :options="EnvelopeOptions"
                v-model="data.item.envelopeId"></model-select>
            </b-form-group>
            <b-form-group v-if="Newtype == 'Transfer'" label-cols="3" content-cols="9" label="Target Balance"
              label-for="input-SourceBalance">
              <div v-bind:class="TargetBalance < 0 ? 'error' : 'success'">
                {{ formatcurency(TargetBalance) }}
              </div>
            </b-form-group>
          </b-modal>
        </b-button-group>
      </template>
    </b-table>
    <b-row>
      <b-col md="6" class="my-1">
        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"
          aria-controls="my-table"></b-pagination>
      </b-col>
    </b-row>
    <NewItem newtype="Transaction" />
    <b-tab></b-tab>
    <SplitEditing :EditTransactionData="TransactionSplitEdit" />
    <!-- (ImportTargetAccount == null || importresults.length < 0) -->
    <b-modal size="lg" @hide="preventHide" @hidden="resetImportModal" @ok="importcsv"
      :ok-disabled="ImportTargetAccount == null || importresults.length < 1 || showAllTransactions == false" title="Import Transactions"
      id="ImportTransactions">
      <b-row>
        <b-col cols="7">
          <b-form-group label-cols-sm="4" label-cols-lg="3" content-cols-sm content-cols-lg="7" label="Account"
            label-for="input-Account">
            <model-select id="input-Account" :options="AccountOptions" v-model="ImportTargetAccount"></model-select>
          </b-form-group>
        </b-col>
        <b-col cols="5">
          <b-form-file ref="importcsv" v-model="file" placeholder="ImportCSV" drop-placeholder="ImportCSV"
            @change="uploadCSV" accept=".csv"></b-form-file>
        </b-col>
        <b-row> </b-row>
      </b-row>
      <div v-if="importresults.length > 0 && ImportedcsvColumns.length > 3">
        <b-card header="CSV Field Selection">
          <b-row>
            <b-form-group label-cols-sm="11" label-cols-lg="11" content-cols-sm="1" content-cols-lg="1"
              label="Seperate columns for transation amounts.">
              <b-form-checkbox v-model="ImportCSVSeperateAmountColumn">
              </b-form-checkbox>
            </b-form-group>
          </b-row>

          <div v-bind:key="i.property" v-for="i in ImportCSVFieldOptions">
            <div v-if="ImportCSVSeperateAmountColumn">
              <b-row v-if="i.field != 'amount'">
                <b-col cols="7">
                  <b-form-group label-cols-sm="5" label-cols-lg="4" content-cols-sm="7" content-cols-lg="8"
                    :label="i.text">
                    <b-select :options="ImportedcsvColumns" v-model="i.csv"></b-select>
                  </b-form-group>
                </b-col>
                <b-col cols="5" v-if="i.csv != null">
                  <div v-if="i.field == 'amount'" v-bind:class="importresults[0][i.csv] < 0 ? 'error' : 'success'
                        ">
                    {{ formatcurency(importresults[0][i.csv]) }}
                  </div>
                  <div v-if="i.field != 'amount'">
                    {{ importresults[0][i.csv] }}
                  </div>
                </b-col>
              </b-row>
            </div>
            <div v-else>
              <b-row v-if="i.field != 'debitamount' && i.field != 'creditamount'">
                <b-col cols="7">
                  <b-form-group label-cols-sm="5" label-cols-lg="4" content-cols-sm="7" content-cols-lg="8"
                    :label="i.text">
                    <b-select :options="ImportedcsvColumns" v-model="i.csv"></b-select>
                  </b-form-group>
                </b-col>
                <b-col cols="5" v-if="i.csv != null">
                  <div v-if="i.field == 'amount'" v-bind:class="importresults[0][i.csv] < 0 ? 'error' : 'success'
                        ">
                    {{ formatcurency(importresults[0][i.csv]) }}
                  </div>
                  <div v-if="i.field != 'amount'">
                    {{ importresults[0][i.csv] }}
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-card>
        <b-card v-if="showAllTransactions" :header="AllTransactionsHeader" v-bind:style="AllTransactionsHeaderStyle">
          <b-table striped hover outlined bordered responsive show-empty :items="csvImportRemapped"
            :fields="CSVImportfields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter">
            <template v-slot:cell(amountCurrency)="data">
              <div v-bind:class="data.item.amount < 0 ? 'error' : 'success'">
                {{ formatcurency(data.item.amount) }}
              </div>
            </template>
            <template v-slot:cell(DAmountCurrency)="data">
              <div v-bind:class="data.item.debitamount < 0 ? 'error' : 'success'">
                {{ formatcurency(data.item.debitamount) }}
              </div>
            </template>
            <template v-slot:cell(CAmountCurrency)="data">
              <div v-bind:class="data.item.creditamount < 0 ? 'error' : 'success'">
                {{ formatcurency(data.item.creditamount) }}
              </div>
            </template>
            <template v-slot:cell(actions)="data">
              <b-button size="sm" @click="
                    RemoveTransactionFromImportList(
                      data.item.duplicateId,
                      data.index
                    )
                    " class="mr-1" variant="danger">
                <b-icon-trash />
              </b-button>
            </template>
          </b-table>
        </b-card>
      </div>
      <div v-else>
        <h1>There is an issue with your CSV</h1>
      </div>
    </b-modal>
    <b-modal size="lg" @hidden="resetExportModal" @ok="ExportTransactions" id="ExportTransactions" scrollable>
      <b-row>
        <b-col cols="4">
          <b-card>
            <b-form-checkbox v-model="allAccountsSelected" :indeterminate="allAccountsSelectedindeterminate"
              @change="ExportToggleAllAccounts">
              {{ allAccountsSelected ? "Un-select All" : "Select All" }}
            </b-form-checkbox>
            <b-form-group label="Accounts" v-slot="{ ariaDescribedby }">
              <b-form-checkbox-group v-model="AccountIdlist" :options="ExportAccountOptions"
                :aria-describedby="ariaDescribedby" stacked></b-form-checkbox-group>
            </b-form-group>
          </b-card>

        </b-col>
        <b-col cols="8">
          <b-card>
            <b-form-checkbox v-model="allEnvelopesSelected" :indeterminate="allEnvelopesSelectedIndeterminate"
              @change="ExportToggleAllEnvelopes">
              {{ allEnvelopesSelected ? "Un-select All" : "Select All" }}
            </b-form-checkbox>
            <b-form-group label="Envelopes" v-slot="{ ariaDescribedby }">
              <b-form-checkbox-group v-model="EnvelopeIdlist" :options="ExportEnvelopeOptions"
                :aria-describedby="ariaDescribedby" stacked></b-form-checkbox-group>
            </b-form-group>
          </b-card>

        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import SplitEditing from "./SplitEditing.vue";
import NewItem from "./NewItem.vue";
import { ModelSelect } from "vue-search-select";

export default {
  name: "Transactions",
  components: {
    SplitEditing,
    NewItem,
    ModelSelect,
  },
  watch: {
    AccountIdlist(newValue, oldValue) {
      if (newValue.length === 0) {
        this.allAccountsSelectedindeterminate = false
        this.allAccountsSelected = false
      } else if (newValue.length === this.ExportAccountOptions.length) {
        this.allAccountsSelectedindeterminate = false
        this.allAccountsSelected = true
      } else {
        this.allAccountsSelectedindeterminate = true
        this.allAccountsSelected = false
      }
    },
    EnvelopeIdlist(newValue, oldValue) {
      if (newValue.length === 0) {
        this.allEnvelopesSelectedIndeterminate = false
        this.allEnvelopesSelected = false
      } else if (newValue.length === this.ExportEnvelopeOptions.length) {
        this.allEnvelopesSelectedIndeterminate = false
        this.allEnvelopesSelected = true
      } else {
        this.allEnvelopesSelectedIndeterminate = true
        this.allEnvelopesSelected = false
      }
    }
  },
  methods: {
    redirectToReceiptUpload() {
      this.$router.push("/receiptupload");
    },
    ExportToggleAllAccounts(checked) {
      //need to add only the value property of the items in checked variable into the this.accountidlist variable
      this.AccountIdlist = checked ? this.ExportAccountOptions.map(item => item.value).slice() : []
    },
    ExportToggleAllEnvelopes(checked) {
      this.EnvelopeIdlist = checked ? this.ExportEnvelopeOptions.map(item => item.value).slice() : []
    },
    onRowSelected(items) {
      this.SelectedTableRows = items;
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    RemoveTransactionFromImportList(duplicateId, index) {
      console.log(duplicateId);
      console.log(index);
      this.importresults.splice(index, 1);

      //remove from importresults the item with the id of duplicateId
      if (duplicateId) {
        this.importresults = this.importresults.filter((i) => {
          return i.duplicateId != duplicateId;
        });
      }

      // this.ImportDuplicates.splice(spliceindex, 1);
    },
    Filtertransactions() {
      if (this.ShowOnlyUnallocated && this.ShowNoAccount) {
        this.transactionsFiltered = this.transactions.filter((i) => {
          return i.envelopeId == null && !i.split && i.accountId == null;
        });
        return
      } else if (this.ShowOnlyUnallocated) {
        this.transactionsFiltered = this.transactions.filter((i) => {
          return i.envelopeId == null && !i.split;
        });
        return
      } else if (this.ShowNoAccount) {
        this.transactionsFiltered = this.transactions.filter((i) => {
          return i.accountId == null;
        });
        return
      } else {
        this.transactionsFiltered = this.transactions;
      }
    },
    resetImportModal() {
      //this.$refs["importcsv"].reset();
      this.file = null;
      this.ImportTargetAccount = null;
      this.importresults = [];
    },
    resetExportModal() {
      this.EnvelopeIdlist = [];
      this.AccountIdlist = [];
    },
    onFiltered(filteredItems) {
      //// console.log("test");
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    UpdateSplitTransaction(data) {
      //// console.log("SubmitSplit");
      //// console.log(data);
    },
    ImportCheckForDuplicates() {
      if (this.csvImportRemapped) {
        axios
          .post("/api/transactions/duplicatecheck", this.csvImportRemapped)
          .then((r) => {
            this.ImportDuplicates = r.data;
          });
      }
    },
    ExportTransactions() {

      axios.post("/api/TransactionExport",
        {
          endDay: "",
          startDay: "",
          EnvelopeIdlist: this.EnvelopeIdlist,
          AccountIdlist: this.AccountIdlist
        }

      )
        //then download csv data returned by axios request
        .then((response) => {
          if (response.data.message == "No Transactions Found") {
            alert("No transactions found for the selected filters.");
            return;
          } else {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "TransactionExport.csv");
            document.body.appendChild(link);
            link.click();
          }
        });
    },
    preventHide(bvModalEvt) {
      if (this.preventModalHide) {
        bvModalEvt.preventDefault();
        this.preventModalHide = false;
      }
    },
    importcsv() {
      this.preventModalHide = false;
      if (this.duplicatesfound) {
        if (
          !confirm(
            `There seem to be duplicates in the transactions you want to import. Do you still want to import these suspected duplicates?`
          )
        ) {
          this.preventModalHide = true;
          return;
        }
      }
      axios.post("/api/ImportCSVFieldOptions", this.ImportCSVFieldOptions);
      axios
        .post("/api/transactions/bulkimport", this.csvImportRemapped)
        .then((e) => {
          setTimeout(
            function () {
              //// console.log("test");
              this.refresh();
            }.bind(this),
            1000
          );
        });
    },
    uploadCSV(e) {
      var files = e.target.files || e.dataTransfer.files;
      //console.log(files);
      let filename = files[0].name;
      if (!files.length) return;
      const file = files[0];
      const reader = new FileReader();
      var results;
      reader.onload = () => {
        try {
          results = this.csvToJson(reader.result);
          this.importresults = results;
        } catch (error) {
          console.log(error);
          alert(error);
          this.fileerror = error;
        }
      };
      reader.readAsText(file);
      setTimeout(
        function () {
          this.$refs["importcsv"].reset();
          this.file = null;
          this.ImportCheckForDuplicates();
        }.bind(this),
        750
      );
    },
    saveTransaction(i) {
      var transactionIndex = this.transactions.findIndex((t) => {
        return t.id == i.id;
      });

      if (i.edit) {
        this.transactions[transactionIndex].edit = false;
        //// console.log(i);
        if (i.split) {
          i.envelopeId = null;
          i.categoryId = null;
          if (i.SplitList) {
            axios.post("/api/transactionsplits", {
              SplitList: i.SplitList,
              transactionId: i.id,
              transactionDate: i.date,
            });
          }
        }
        axios.post("/api/transactions", i).then((p) => {
          // console.log(p.data);
          var envelope = this.Envelopes.filter((t) => {
            return t.id == i.envelopeId;
          });
          var account = this.Accounts.filter((t) => {
            return t.id == i.accountId;
          });
          //// console.log(envelope[0].name);
          this.transactions[transactionIndex].envelope = envelope[0];
          this.transactions[transactionIndex].account = account[0];
        });

        // setTimeout(
        //   function () {
        //     this.refresh();
        //   }.bind(this),
        //   800
        // );
      } else {
        this.transactions[transactionIndex].edit = true;
      }
    },
    BulkAssignEnvelope(list) {
      var index = this.Envelopes.findIndex((t) => {
        return t.id == this.BulkAssignEnvelopeId;
      });
      var targetenvelope = this.Envelopes[index];
      console.log(targetenvelope);
      if (
        confirm(
          `Assign all selected transactions to ${targetenvelope.category.name} | ${targetenvelope.name}?`
        )
      ) {
        axios
          .post("/api/transactions/bulkassign", {
            list: this.SelectedTableRows,
            targetenvelopeid: this.BulkAssignEnvelopeId,
          })
          .then(
            setTimeout(
              function () {
                this.refresh();
              }.bind(this),
              1000
            )
          );
      }
    },
    BulkDelete(list) {
      if (
        confirm(`Are you sure you want to delete these selected transactions?`)
      ) {
        list.forEach((i) => {
          var index = this.transactions.findIndex((t) => {
            return t.id == i.id;
          });

          //// console.log(index);
          axios
            .delete("/api/transactions", {
              data: i,
            })
            .then(this.transactions.splice(index, 1));
        });
        //// console.log(index);
      }
    },
    Delete(i) {
      //// console.log(i);
      var index = this.transactions.findIndex((t) => {
        return t.id == i.id;
      });
      if (
        confirm(`Delete transaction? ${i.date} - ${i.payee} - $${i.amount}`)
      ) {
        //// console.log(index);
        axios
          .delete("/api/transactions", {
            data: i,
          })
          .then(this.transactions.splice(index, 1));
      }
    },
    getFilters(obj, excludeFields) {
      let fields = Object.keys(obj[0]);
      fields = fields.filter((n) => !excludeFields.includes(n));
      let f = [];
      for (const field of fields) {
        f.push({
          field: field,
          values: [...new Set(obj.map((r) => r[field]))],
        });
      }
      return f;
    },
    GetTransactions() {
      this.TransactionTableisBusy = true;
      console.log("Getting Transactions");
      axios
        .get(
          "/api/transactions/range/" +
          this.SearchDateStart +
          "/" +
          this.SearchDateEnd
        )
        .then((res) => {
          // this.Datetransactions = res.data;
          this.transactions = res.data.map((i) => {
            i.edit = false;
            i.amount = Number(i.amount).toFixed(2);
            return i;
          });
          this.totalRows = this.transactions.length;
          this.Filtertransactions();
          this.TransactionTableisBusy = false;
        });
    },
    refresh() {
      // this.TransactionTableisBusy = true;
      // axios.get("/api/transactions").then((res) => {
      //   // console.log(res.data);
      //   this.transactions = res.data.map((i) => {
      //     i.edit = false;
      //     i.amount = Number(i.amount).toFixed(2);
      //     return i;
      //   });
      //   this.totalRows = this.transactions.length;
      //   this.Filtertransactions();
      //   // this.CustomFilters = this.getFilters(res.data, [
      //   //   "id",
      //   //   "userid",
      //   //   "date",
      //   //   "amount",
      //   //   "edit",
      //   //   "transactionsplits",
      //   //   "updatedAt",
      //   //   "createdAt",
      //   // ]);
      // });
      this.GetTransactions();
      axios.get("/api/Envelopes").then((res) => (this.Envelopes = res.data));
      axios.get("/api/Accounts").then((res) => (this.Accounts = res.data));
      axios.get("/api/ImportCSVFieldOptions").then((res) => {
        //// console.log(res.data);
        if (res.data.length < 5) {
          this.ImportCSVFieldOptions = [
            {
              text: "Date",
              field: "date",
              csv: null,
            },
            {
              text: "Payee",
              field: "payee",
              csv: null,
            },
            {
              text: "Description",
              field: "description",
              csv: null,
            },
            {
              text: "Amount",
              field: "amount",
              csv: null,
            },
            {
              text: "Credit Amount",
              field: "creditamount",
              csv: null,
            },
            {
              text: "Debit Amount",
              field: "debitamount",
              csv: null,
            },
            {
              text: "Check #",
              field: "checknumber",
              csv: null,
            },
          ];
        } else {
          const creditexists = res.data.find((x) => x.field == "creditamount");
          if (!creditexists) {
            res.data.unshift({
              text: "Credit Amount",
              field: "creditamount",
              csv: null,
            });
          }
          const debitexists = res.data.find((x) => x.field == "debitamount");
          if (!debitexists) {
            res.data.unshift({
              text: "Debit Amount",
              field: "debitamount",
              csv: null,
            });
          }
          this.ImportCSVFieldOptions = res.data;
        }
      });
    },
  },
  data() {
    return {
      preventModalHide: false,
      TransactionTableisBusy: false,
      allEnvelopesSelectedIndeterminate: false,
      allAccountsSelectedindeterminate: false,
      allEnvelopesSelected: false,
      allAccountsSelected: false,
      EnvelopeIdlist: [],
      AccountIdlist: [],
      window: {
        width: 0,
        height: 0,
      },
      ImportCSVSeperateAmountColumn: false,
      TableSelectModeChecked: false,
      CheckQ: false,
      BulkAssignEnvelopeId: "",
      Newtype: "Transaction",
      NewSelect: ["Envelope", "Category", "Account", "Transaction", "Transfer"],
      totalRows: 1,
      ImportDuplicates: [],
      CustomFilters: [],
      ImportCSVFieldOptions: [
            {
              text: "Date",
              field: "date",
              csv: null,
            },
            {
              text: "Payee",
              field: "payee",
              csv: null,
            },
            {
              text: "Description",
              field: "description",
              csv: null,
            },
            {
              text: "Amount",
              field: "amount",
              csv: null,
            },
            {
              text: "Credit Amount",
              field: "creditamount",
              csv: null,
            },
            {
              text: "Debit Amount",
              field: "debitamount",
              csv: null,
            },
            {
              text: "Check #",
              field: "checknumber",
              csv: null,
            },
          ],
      importresults: [],
      ImportTargetAccount: null,
      file: null,
      currentPage: 1,
      SearchDateStart: new Date().addDays(-60),
      SearchDateEnd: new Date(),
      perPage: 12,
      sortBy: "date",
      sortDesc: true,
      ShowOnlyUnallocated: false,
      ShowNoAccount: false,
      sortDirection: "asc",
      filter: null,
      SelectedTableRows: [],
      TransactionSplitEdit: {},
      Envelopes: [],
      CurrentEnvelope: [],
      Accounts: [],
      transactionsFiltered: [],
      transactions: [],
      Datetransactions: [],
      CSVImportfields: [
        {
          key: "date",
          label: "Date",
          class: "col-1",
          variant: "none",
          sortDirection: "desc",
        },
        {
          key: "payee",
          label: "Payee",
          variant: "none",
          class: "col-1",
        },
        {
          key: "description",
          label: "Description",
          variant: "none",
          class: "col-2",
        },
        {
          key: "checknumber",
          label: "Check #",
          variant: "none",
          class: "col-1",
        },

        {
          key: "amountCurrency",
          label: "Amount",
          variant: "none",
          class: "col-2",
        },

        {
          key: "actions",
          label: "",
          variant: "none",
          class: "col-1",
        },
      ],
    };
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.refresh();

    // axios
    //   .get("/custcontactstatuses")
    //   .then((res) => (this.AllStatuses = res.data));
    // //.then(res => (// console.log(res.data)));
    // //.catch(err => // console.log(err));
  },
  computed: {
    transactionsfields() {
      if (this.isMobile) {
        return [
          {
            key: "DateEdit",
            label: "Date",
            class: "col-1",
            variant: "none",
            sortDirection: "desc",
          },
          {
            key: "PayeeEdit",
            label: "Payee",
            variant: "none",
            class: "col-2",
          },
          {
            key: "CheckNumberEdit",
            label: "CK#",
            variant: "none",
            class: "col-1",
          },

          {
            key: "AmountEdit",
            label: "Amount",
            variant: "none",
            class: "col-2",
          },
          {
            key: "EnvelopeEdit",
            label: "Envelope",
            variant: "none",
            class: "col-2",
          },
          {
            key: "Actions",
            label: "",
            variant: "none",
            class: "col-1",
          },
        ];
      } else {
        return [
          // {
          //   key: "selected",
          //   label: "",
          //   class: "col-1",
          //   variant: "none",
          //   sortDirection: "desc",
          // },
          {
            key: "DateEdit",
            label: "Date",
            class: "col-1",
            variant: "none",
            sortDirection: "desc",
          },
          {
            key: "AccountEdit",
            label: "Account",
            variant: "none",
            class: "col-1",
          },
          {
            key: "PayeeEdit",
            label: "Payee",
            variant: "none",
            class: "col-2",
          },
          {
            key: "CheckNumberEdit",
            label: "CK#",
            variant: "none",
            class: "col-1",
          },

          {
            key: "AmountEdit",
            label: "Amount",
            variant: "none",
            class: "col-2",
          },
          {
            key: "EnvelopeEdit",
            label: "Envelope",
            variant: "none",
            class: "col-2",
          },
          {
            key: "Actions",
            label: "",
            variant: "none",
            class: "col-1",
          },
        ];
      }
    },
    isMobile() {
      console.log("this.window.width");
      console.log(this.window.width);
      if (this.window.width < 500) {
        return true;
      } else {
        return false;
      }
    },
    TableSelectMode() {
      console.log("this.window.width");
      console.log(this.window.width);
      if (this.TableSelectModeChecked) {
        return "range";
      } else {
        return "multi";
      }
    },
    duplicatesfound() {
      if (this.ImportDuplicates) {
        console.log("this.ImportDuplicates");
        console.log(this.ImportDuplicates);
        var count = 0;
        this.ImportDuplicates.map((d) => {
          if (this.csvImportRemapped[d.index]) {
            count++;
            // console.log("count");
            // console.log(count);
          } else {
          }
        });
        // console.log("count");
        // console.log(count);
        if (count > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    showAllTransactions() {
      console.log("showAllTransactions");
      if (this.ImportCSVFieldOptions) {
        console.log("true")
        const optionalFields = this.ImportCSVSeperateAmountColumn
          ? ['description', 'amount']
          : ['description', 'debitamount', 'creditamount'];
        
          console.log(optionalFields);
        console.log(this.ImportCSVFieldOptions);

        let a = this.ImportCSVFieldOptions.some(option => {
          return !optionalFields.includes(option.field) && option.csv === null;
        });
        return !a;
      } else {
        return false;
      }

    },
    AllTransactionsHeaderStyle() {
      if (this.duplicatesfound) {
        return { color: "red" };
      } else {
        return { color: "black" };
      }
    },
    csvImportRemapped() {
      if (this.importresults.length > 0) {
        // console.log("Testing");
        const remap = this.importresults.map((r) => {
          const d = {};
          this.ImportCSVFieldOptions.forEach((f) => {
            // console.log(f.csv);
            // console.log(r[f.csv]);
            if (f.csv != null) {
              d[f.field] = r[f.csv] || null;
            }
          });
          d.accountId = this.ImportTargetAccount;
          return d;
        });

        // console.log(remap);
        const results = remap.map((k) => {
          k.amount = Number(
            k.amount || k.creditamount || k.debitamount
          ).toFixed(2);
          return k;
        });
        // console.log(results);
        const filteredresults = results.filter((t) => {
          return t.amount != null;
        });

        // const duplicates = await axios.post(
        //   "/api/transactions/duplicatecheck",
        //   results
        // );

        if (this.ImportDuplicates && this.ImportDuplicates.length > 0) {
          this.ImportDuplicates.forEach((d, index) => {
            // console.log("d");
            // console.log(d);
            filteredresults.map((result) => {
              if (result.date + result.payee + result.amount + result.checknumber + result.accountId === d.id) {
                result._rowVariant = "warning";
                result.duplicateId = d.id;
              }
            });


            // if (filteredresults[d.index]) {
            //   filteredresults[d.index]._rowVariant = "warning";
            //   filteredresults[d.index].id = d.id;
            // }
          });
        }

        // // console.log("duplicates");
        // // console.log(duplicates);

        // console.log(results);
        return filteredresults;
      } else {
        return null;
      }
    },

    ImportedcsvColumns() {
      if (this.importresults.length > 0) {
        var i = Object.keys(this.importresults[0]).map((k) => {
          return {
            value: k,
            text: k,
          };
        });
        i.unshift({
          value: null,
          text: "--Select Field--",
        });
        return i;
      }
    },
    ExportEnvelopeOptions() {
      if (this.Envelopes) {
        var b = this.Envelopes.map((i) => ({
          value: i.id,
          text: `${i.category.name} | ${i.name}`,
        }));
        return b;
      } else {
        return null;
      }
    },
    EnvelopeOptions() {
      if (this.Envelopes) {
        var b = this.Envelopes.map((i) => ({
          ...i,
          value: i.id,
          text: `${i.category.name} | ${i.name}`,
        }));
        b.unshift({
          value: null,
          text: "-- Select Envelope --",
        });
        return b;
      } else {
        return {
          value: null,
          text: "None",
        };
      }
    },
    ExportAccountOptions() {
      if (this.Accounts) {
        return this.Accounts.map((i) => ({
          value: i.id,
          text: ` ${i.name}`,
        }));
      } else {
        return null;
      }
    },
    AccountOptions() {
      if (this.Accounts) {
        return this.Accounts.map((i) => ({
          ...i,
          value: i.id,
          text: ` ${i.name}`,
        }));
      } else {
        return [{
          value: null,
          text: "None",
        }];
      }
    },
    transactionsCalculated() {
      var data = this.transactions;
      if (this.transactions) {
        //data.push()
      }
      return data;
    },
    AllTransactionsHeader() {
      const header = `All Transactions (${this.importresults.length || 0})`;
      if (this.duplicatesfound) {
        return header + " - Duplicates Found!!!";
      } else {
        return header;
      }
    },
  },
};
</script>

<style scoped>
@media (min-width: 320px) and (max-width: 578px) {
  ::v-deep #my-table.table {
    font-size: 9px !important;
  }
}

::v-deep .tab-res .table-responsive {
  overflow-x: visible;
}

::v-deep fieldset legend {
  color: #333;
  /* sets the text color */
  font-size: 2em;
  /* sets the font size */
  font-weight: bold;
  /* sets the font weight */
}

::v-deep a {
  cursor: pointer;
}

::v-deep .b-table-row-selected.table-active {
  background-color: rgba(230, 255, 140, 0.57) !important;
}
</style>