<template>
  <div>
    <b-button @click="GetPlaidLinkToken">Refresh</b-button>
    <label for="start_date">Import Transactions from:</label>
    <b-input-group prepend="Start Date">
      <b-input id="start_date" type="date" v-model="start_date"></b-input>
    </b-input-group>
    <div v-if="PlaidLinkToken != ''">
      <PlaidLinkComp :env="PlaidEnv" :onLoad="onLoad" :link_token="PlaidLinkToken" :onSuccess="onSuccess" :onExit="onExit"
        :onEvent="onEvent">
        <b-button>Add</b-button>
      </PlaidLinkComp>
    </div>

  </div>
</template>

<script>
import PlaidLinkComp from "vue-plaid-link2";
import axios from "axios";

export default {
  name: "Plaid",
  data() {
    return {
      backdate: false,
      PlaidLinkToken: "",
      PlaidEnv: "",
      token: "",
      start_date: new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().split('T')[0],
      end_date: "",
    };
  },
  components: { PlaidLinkComp },
  computed: {},
  methods: {
    GetPlaidLinkToken() {
      this.PlaidLinkToken = "";
      axios.post("/api/plaid/create_link_token").then((r) => {
        // console.log(r);
        this.PlaidLinkToken = r.data.link_token;
        this.PlaidEnv = r.data.link_token.split("-")[1];
      });
    },
    GetTransactions() {
      axios
        .post("/api/plaid/transactions/get", {
          start_date: this.start_date,
          end_date: this.end_date,
        })
        .then((r) => {
          console.log(r);
        });
    },
    onLoad() {
      console.log("onLoad");
    },
    async onSuccess(public_token, metadata) {
      console.log("onSuccess", public_token, metadata);
      console.log("onSuccess");
      this.$parent.loading = true;
      try {
        await axios.post("/api/plaid/exchange_public_token", { public_token, start_date: this.start_date });
        
      } catch (error) {
        console.error(error);
        alert("An error occurred while connecting your account.");
      }
      this.$emit('Refresh');
      this.$parent.loading = false;
    },
    onExit(err, metadata) {
      console.log("onExit");
      console.log("onExit", err, metadata);
    },
    onEvent(eventName, metadata) {
      console.log(`onEvent: ${eventName}`);
      console.log("onEvent", eventName, metadata);
    },
  },
  mounted() {
  this.GetPlaidLinkToken();
  },
};
</script>
